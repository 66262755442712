/* Sticky Footer Classes */

html,
body {
  height: 100%;
}

#page-content {
  flex: 1 0 auto;
}

#sticky-footer {
  flex-shrink: none;
}

/* Other Classes for Page Styling */

/* body {
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
} */